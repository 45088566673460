import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProductPage = () => (
  <Layout>
    <SEO title="Product" keyword={'gatsby','application','react'} />
    
  <Description/>
  </Layout>
)
function Description(){
   return <p1>PComing Soon</p1>
}


function App(){
  return (
      <div>
          <div>Smash that like</div>
      </div>
  )
}

export default ProductPage
